@import 'sources/styles/variables.scss';

.container {
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;
  position: relative;
}

.slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;

  .slide {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    width: 1080px !important;
    height: 1920px !important;

    p {
      color: #fff;
      font-style: normal;
      font-weight: 300;
      font-size: 52px;
      line-height: 62px;
      margin-top: 95px;
      margin-left: 11.11%;
      margin-right: 15.28%;

      strong {
        font-weight: 500;
      }
    }

    &.slide0 {
      background: #6C7B67;
    }

    &.slide1 {
      background: #B4A0A0;
    }

    &.slide2 {
      background: #797487;
    }

    &.slide3 {
      background: #7B8A9C;
    }
  }
}

.sliderDots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 46px;
  width: 100%;

  > div {
    width: 45px;
    height: 45px;
    margin: 0 13px;
    border: 8px solid #fff;
    border-radius: 25px;

    &.activeDot {
      background: #fff;
    }
  }
}

.videoWrapper {
  width: 1080px;
  position: relative;

  &.videoStopped:after {
    content: '\f04b';
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    border: 8px solid #FFFFFF;
    width: 226px;
    height: 226px;
    top: calc(50% + 80px);
    left: calc(50% - 113px);
    z-index: 2;
    border-radius: 113px;
    font-family: FontAwesome;
    text-align: center;
    color: #fff;
    padding-top: 15px;
    padding-left: 20px;
    font-size: 120px;
  }
}

.video {
  width: 100%;
  margin-top: 445px;
}

.image {
  margin-top: 445px;
}

.redImage {
  margin-top: 445px;
  width: 100%;
  background: #cf213c;
  height: 609px;

  img {
    width: 720px;
    height: 540px;
    margin: 30px 160px;
  }
}

.whiteImage {
  margin-top: 445px;
  width: 100%;
  background: #fff;
  height: 609px;

  img {
    width: 720px;
    height: 540px;
    margin: 30px 160px;
  }
}