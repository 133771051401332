@import 'sources/styles/variables.scss';

.container {
  background: #011E41;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.desc {
  position: absolute;
  left: 11.11%;
  right: 15.28%;
  top: 48%;
  bottom: 7.92%;
  font-style: normal;
  font-weight: 300;
  font-size: 52px;
  line-height: 62px;
  color: #fff;

  a {
    display: block;
    color: #fff;
    margin-top:60px;
    margin-bottom: 125px;
    font-weight: 500;

    &:hover {
      color: #fff;
    }

    span {
      font-weight: 100;
      font-size: 32px;
    }
  }

  strong {
    font-weight: 500;
  }
}

.electrolux {
  align-self: center;
  margin-top: 525px;
  position: relative;
  left: -40px;
}