@import 'sources/styles/variables.scss';

.container {
  background: #011E41;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.brandIdea {
  width: 100%;
  margin-top: 445px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 46px;
  color: #FFFFFF;
  margin-left: 12.5%;
  margin-right: 13.89%;
  margin-top: 445px;
}
