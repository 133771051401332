@import 'sources/styles/variables.scss';

.container {
  background: #011E41;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.str {
  font-family: ElectroluxSans;
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 92px;
  text-align: center;
  color: #ADB9C3;
  align-self: center;
  margin-top: 460px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  align-self: center;
  margin-top: 45px;

  li {
    margin: 10px 0;

    a {
      font-size: 52px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      display: block;
      border: 8px solid;
      margin: 5px 100px;
      padding: 40px 0;
    }
  }
}

.title {
  position: relative;
  display: block;
  height: 52px;
  margin-top: -80px;

  .titleNormal {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 50px;
    text-transform: uppercase;
    color: #323333;
  }
  
  .titleFresh {
    font-family: Custom Fresh;
    font-style: normal;
    font-weight: normal;
    font-size: 122px;
    line-height: 147px;
    color: #EE405A;
  }
}