@import 'sources/styles/variables.scss';

.container {
  background: #011e41;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;
}

.slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;

  .slide {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    width: 1080px !important;
    height: 1920px !important;

    img {
      width: 100%;
      margin-top: 445px;
    }

    video {
      width: 100%;
      margin-top: 445px;
    }

    p {
      color: #f9f9f9;
      font-weight: 300;
      font-size: 32px;
      line-height: 46px;
      margin: 93px 11.11% 50px 11.11%;

      strong {
        font-weight: 500;
      }

      &.bolder {
        font-style: normal;
        font-weight: normal;
        font-size: 52px;
        line-height: 62px;
      }
      &.list {
        > b {
          font-weight: 500;
        }
        > ul {
          line-height: 55px;
        }
      }
    }
  }
}

.sliderDots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 46px;
  width: 100%;

  > div {
    width: 45px;
    height: 45px;
    margin: 0 13px;
    border: 8px solid #fff;
    border-radius: 25px;

    &.activeDot {
      background: #fff;
    }
  }
}
