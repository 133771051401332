@import 'sources/styles/variables.scss';

.container {
  background: #011E41;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image {
  width: 100%;
  margin-top: 445px;
}

.desc {
  margin-left: 12.5%;
  margin-right: 13.89%;
  margin-top: 450px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 46px;
  color: #FFFFFF;

  span {
    color: #D1132F;
  }

  a {
    color: #fff;

    span {
      font-size: 21px;
      position: relative;
      top: 2px;
    }

    &:hover {
      color: #fff;
    }
  }
}

.button {
  font-weight: 500;
  font-size: 52px;
  line-height: 92px;
  text-align: center;
  color: #FFFFFF;
  border: 8px solid #FFFFFF;
  box-sizing: border-box;
  margin-left: 9.63%;
  margin-right: 9.63%;
  margin-top: 76px;
  display: block;
  padding: 33px 0;
}
