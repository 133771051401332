@import 'sources/styles/variables.scss';

.container {
  background: #011e41;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image {
  width: 100%;
  margin-top: 445px;
}

.desc {
  margin-left: 11.11%;
  margin-right: 11.11%;
  margin-top: 95px;
  font-style: normal;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 46px;
  color: #ffffff;

  strong {
    font-weight: 500;
  }

  a {
    color: #fff;
    font-weight: 500;

    span {
      font-size: 21px;
      position: relative;
      top: 2px;
    }

    &:hover {
      color: #fff;
    }
  }
}

.sourceGlobal {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #d4d8d2;
  margin-top: 70px;
  margin-left: 11.11%;
}
