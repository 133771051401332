@import 'sources/styles/variables.scss';

.header {
  position: absolute;
  top: 74px;
  left: 76px;
  right: 76px;
  z-index: 100;
  display: flex;
  flex-direction: column;

  &.dark {
    a {
      color: $darkBlue;

      &:last-child {
        &:after {
          background: $darkBlue;
        }
      }
    }

    .bottom {
      .title {
        color: $darkBlue;
      }

      .back {
        background: url(sources/images/back-blue-icon.png);
      }
    }

    .delimiter {
      background: url(sources/images/breadcrumb-blue-delimiter.png);
    }
  }

  .home {
    width: 60px;
    height: 72px;
    display: block;
    top: -5px;
    left: -9px;
    position: relative;
    margin-right: 6px;
    z-index: 1;
  }

  a {
    float: left;
    font-size: 32px;
    font-weight: 500;
    color: #fff;

    &:focus,
    &:active {
      color: #fff;
    }

    &:last-child:not(:first-child) {
      position: relative;
      
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 5px;
        left: 0;
        bottom: 0;
        background: #323333;
      }
    }
  }
}

.delimiter {
  width: 11px;
  height: 20px;
  background: url(sources/images/breadcrumb-delimiter.png);
  margin: 15px 11px;
  float: left;
}

.bottom {
  display: flex;
  margin-top: 48px;
  position: relative;

  .back {
    align-self: center;
    width: 45px;
    height: 76px;
    background: url(sources/images/back-icon.png);
    top: -5px;
    left: -2px;
    position: relative;
  }

  .title {
    font-weight: 500;
    font-size: 83px;
    text-align: center;
    line-height: normal;
    margin: 0;
    padding: 0;
    color: #fff;
    flex: 1;
    padding-right: 45px;
    align-self: center;

    &.titleMetric {
      font-family: 'Metric';
      font-weight: 400;
      position: relative;
      top: 7px;
    }
    
    .freshTitle {
      font-family: 'Custom Fresh';
      color: #EE405A;
    }
  }
}
