// Metric
@font-face {
  font-family: 'Metric';
  font-weight: 100;
  font-style: normal;
  src: url(../fonts/Metric-Thin.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 100;
  font-style: italic;
  src: url(../fonts/Metric-ThinItalic.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/Metric-Light.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 300;
  font-style: italic;
  src: url(../fonts/Metric-LightItalic.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/Metric-Regular.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 400;
  font-style: italic;
  src: url(../fonts/Metric-RegularItalic.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/Metric-Semibold.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/Metric-SemiboldItalic.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/Metric-Bold.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 700;
  font-style: italic;
  src: url(../fonts/Metric-BoldItalic.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 900;
  font-style: normal;
  src: url(../fonts/Metric-Black.ttf);
}
@font-face {
  font-family: 'Metric';
  font-weight: 900;
  font-style: italic;
  src: url(../fonts/Metric-BlackItalic.ttf);
}

// MetricWeb
@font-face {
  font-family: 'MetricWeb';
  font-weight: 100;
  font-style: normal;
  src: url(../fonts/MetricWeb-Thin.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 100;
  font-style: italic;
  src: url(../fonts/MetricWeb-ThinItalic.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/MetricWeb-Light.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 300;
  font-style: italic;
  src: url(../fonts/MetricWeb-LightItalic.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/MetricWeb-Regular.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 400;
  font-style: italic;
  src: url(../fonts/MetricWeb-RegularItalic.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/MetricWeb-Semibold.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/MetricWeb-SemiboldItalic.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/MetricWeb-Bold.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 700;
  font-style: italic;
  src: url(../fonts/MetricWeb-BoldItalic.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 900;
  font-style: normal;
  src: url(../fonts/MetricWeb-Black.eot);
}
@font-face {
  font-family: 'MetricWeb';
  font-weight: 900;
  font-style: italic;
  src: url(../fonts/MetricWeb-BlackItalic.eot);
}

// GothamPro
@font-face {
  font-family: 'GothamPro';
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/GothamPro-Light.ttf);
}
@font-face {
  font-family: 'GothamPro';
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/GothamPro.ttf);
}
@font-face {
  font-family: 'GothamPro';
  font-weight: 400;
  font-style: italic;
  src: url(../fonts/GothamPro-Italic.ttf);
}
@font-face {
  font-family: 'GothamPro';
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/GothamPro-Medium.ttf);
}
@font-face {
  font-family: 'GothamPro';
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/GothamPro-Bold.ttf);
}

// ElectroluxSans
@font-face {
  font-family: 'ElectroluxSans';
  font-weight: 100;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_1_Thin.otf);
}
@font-face {
  font-family: 'ElectroluxSans';
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_2_Light.otf);
}
@font-face {
  font-family: 'ElectroluxSans';
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_3_Regular.otf);
}
@font-face {
  font-family: 'ElectroluxSans';
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_4_Semibold.otf);
}
@font-face {
  font-family: 'ElectroluxSans';
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_5_Bold.otf);
}

// ElectroluxSans Oblique
@font-face {
  font-family: 'ElectroluxSans Oblique';
  font-weight: 100;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_Oblique_1_Thin.otf);
}
@font-face {
  font-family: 'ElectroluxSans Oblique';
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_Oblique_2_Light.otf);
}
@font-face {
  font-family: 'ElectroluxSans Oblique';
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_Oblique_3_Regular.otf);
}
@font-face {
  font-family: 'ElectroluxSans Oblique';
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_Oblique_4_Semibold.otf);
}
@font-face {
  font-family: 'ElectroluxSans Oblique';
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/ElectroluxSans_Oblique_5_Bold.otf);
}

// Custom Fresh
@font-face {
  font-family: 'Custom Fresh';
  src: url(../fonts/Custom_Fresh.ttf), url(../fonts/Custom_Fresh.otf);
}

// FontAwesome
@font-face {
  font-family: 'FontAwesome';
  src: url(../fonts/fontawesome.ttf);
}

@import './variables.scss';

html,
body,
#root {
  width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  font-family: ElectroluxSans;
}

a:hover {
  color: #fff;
}

a:focus,
a:active {
  text-decoration: none;
}

.switch-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  width: 1080px;
  height: 1920px;
}

.switch-wrapper > div {
  position: absolute;
  display: flex;
  flex: 1;
  width: 1080px;
  height: 1920px;
}

.fa {
  font-family: FontAwesome;
}
