@import 'sources/styles/variables.scss';

.container {
  background: #011e41;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;
}

.slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1080px;
  height: 1920px;

  .slide {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    width: 1080px !important;
    height: 1920px !important;

    img {
      width: 100%;
      margin-top: 445px;
    }
    div {
      color: #fff;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 46px;
      margin: 93px 11.11% 50px 11.11%;
    }
  }
}

.sliderDots {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 46px;
  width: 100%;

  > div {
    width: 45px;
    height: 45px;
    margin: 0 13px;
    border: 8px solid #fff;
    border-radius: 25px;

    &.activeDot {
      background: #fff;
    }
  }
}

.title {
  text-align: center;
  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 83px;
    line-height: 100px;
    color: #ffffff;
    display: block;
  }

  font-size: 52px;
  padding: 10px;
  line-height: 40px;
  margin-top: 0px;
  display: inline-block;
}
